/* PastOrdersPage.css */
.past-orders-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #2c3e50;
}

.past-orders-list {
  list-style: none;
  padding: 0;
}

.past-order-item {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary {
  margin-top: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-primary {
  background-color: #3498db;
  color: #fff;
}

.btn-primary:hover {
  background-color: #2980b9;
}
.past-orders-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.filters {
  margin-bottom: 20px;
}

.order-list {
  list-style: none;
  padding: 0;
}

.order-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.order-item details {
  margin-top: 10px;
}

.error {
  color: red;
}
