.hover-text {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #000; /* Default text color */
  font-size: .9rem;
  transition: color 0.3s ease;
}

.hover-text:hover {
  color: #555; /* Optional: Change text color on hover */
}

.hover-text:after {
  content: attr(data-hover); /* Fetch text from the data-hover attribute */
  position: absolute;
  top: 50%; /* Position below the original text */
  left: 0;
  width: 100%;
  background-color: #f0f0f0; /* Optional: Background for hover text */
  color: #000; /* Text color for hover text */
  font-size: 0.9rem; /* Adjust hover text size */
  text-align: center;
  padding: 0.1rem;
  border-radius: 0.3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Shadow for hover text */
  opacity: 0; /* Start hidden */
  transform: translateY(-20px); /* Add smooth transition effect */
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none; /* Prevent interfering with hover state */
}

.hover-text:hover:after {
  opacity: 1; /* Show on hover */
  transform: translateY(0); /* Slide into position */
}
