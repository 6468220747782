/* FoodCourse.css */

.course-food-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.course-food-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.course-food-list {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping of list items */
  padding: 0;
  margin: 0;
}

.course-food-list-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.course-food-list-item input[type='checkbox'] {
  margin-right: 10px;
}

.course-food-input {
  width: calc(100% - 80px);
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.course-food-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.course-food-button:hover {
  background-color: #45a049;
}
.food-course-option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.food-course-option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 8px;
}

.food-course-checkbox {
  margin-right: 4px;
  margin-left: 4px;
}

.food-course-label {
  font-size: 14px;
  padding-top: 6px;
  cursor: pointer;
}
