.add-food-item-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}
.italic-text {
    font-style: italic;
}
.btn {
  margin-top: 1px;
}
.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #f0f0f0;
}

.selected-ingredients .badge {
  cursor: pointer;
  background-color: aqua;
  color:black;
}

.selected-ingredients .badge:hover {
  background-color: #dc3545;
  color: white;
}

.column-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
}