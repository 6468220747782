.edit-product-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

.btn {
  margin-top: 10px;
}
