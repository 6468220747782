/* Header Container */
.header-container {
  width: 100%;
   max-width: 400px;
   max-height: 500px;
   overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

/* Search Input */
.input-group {
  width: 100%;
}

.input-group input {
  padding: 10px 15px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Profile Image */
.profile-container {
  width: 10px; /* Adjust size of the profile image container */
  height: 10px;
}

.profile {
  width: auto; /* Ensures the image scales within the container */
  height: auto;
 /*  object-fit: cover; */ /* Ensures the image is properly cropped if necessary */
}

/* Notification Bell Icon */
.bell-icon {
  font-size: 20px;
  color: #333; /* Adjust for better visibility */
  cursor: pointer;
}

/* Row Styling */
.row {
  margin: 0;
  padding: 10px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    gap: 10px;
  }

  .profile-container,
  .bell-icon {
    margin-top: 10px;
  }
}
