.food-item-details-container {
  padding: 20px;
}

.food-item-details {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.food-item-image {
  width: 20rem;
  max-width: 20rem;
  height:20rem;
  border-radius: 5px;
  margin-bottom: 15px;
}

.food-item-info p {
  margin-bottom: 10px;
}

.food-item-info strong {
  font-weight: bold;
}
