.product-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
}

 .btn {
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.product-wrapper .btn:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .course-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .course-wrapper {
    grid-template-columns: 1fr;
  }
}