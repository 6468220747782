/* Reset only necessary elements */
/* html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}
 */
/* Dashboard container */
.d-container {
  display: flex;
  width: 95vw;
 /*  height: 100vh; */
  height: auto;
  
}

/* Vertical Navbar */
.VerticalNavbar {
  width: "20%";
  background-color: #0096ff;
  color: white;
  padding: 0;
}

/* Content area */
.content {
  flex-grow: 0;
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y:unset;
  overflow-x: hidden;
  width: 80vw;
}

/* Products container */
/* .course-container {
  padding: 1px 2px;
  border-radius: 16px;
  background-color: white;
  margin: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.course-container p {
  margin: 5px 0;
}
 */