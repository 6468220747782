body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}
.header {
  background-color: #269faf;
  color: white;
  padding: 10px; /* Provide sufficient padding for spacing */
  display: flex; /* Use Flexbox to align items in a row */
  flex-direction: row;
  align-items: center; /* Vertically align content within the header */
  justify-content: space-between; /* Space out content in the row */
  position: sticky;
  top: 0;
  z-index: 10;
}

.header img.logo {
  border-radius: 50%; /* Add circular styling for the logo */
  width: 50px; /* Set logo width */
  height: 50px; /* Set logo height */
  margin-left: 20px; /* Add space between logo and text */
  object-fit: contain;
}

.header-content {
  flex-grow: 1; /* Allow the content to take up remaining space */
  text-align: left; /* Align text to the left */
  justify-items:center;
}

.header-content h1 {
  margin: 0;
  font-size: 24px;
}

.header-content p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #f1f1f1;
}

.sticky-header {
  background-color: #269faf;
  color: white;
  padding: 0px;
  text-align: center;
  position: sticky;
  top: calc(4.5em + 1px); /* Stick to the top of the page */
  z-index: 10; /* Ensure it stays above other content */
  min-height: 2px;
  transition: all 0.3s ease-in-out; /* Smooth transition for sticky effect */
  display: flex; /* Use flexbox to align items in a row */
  align-items: center; /* Vertically align the logo and text */
}

.sticky-header a {
  color: white;
  padding: 5px 20px;
  text-decoration: none;
}

.sticky-header a:hover {
  background-color: #ddd;
  color: black;
}

.content {
  padding: 20px;
  background-color: #f1f1f1;
}

.content h2 {
  margin-top: 40px;
  font-size: 24px;
}

.navbar {
  overflow: hidden;
  background: #269faf;
}

.navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

.navbar a.right {
  float: right;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}

.row {
  display: flex;
  flex-direction: row;
  /* margin-inline: 4px; */
}

.side {
  flex: 14%;
  background-color: #f1f1f1;
  padding: 4px;
  position: sticky;
  top: calc(5em + 40px); /* Adjust according to the sticky header's height */
  height: fit-content; /* Ensure the height doesn't stretch unnecessarily */
  margin-left: 20px;
}

.main {
  flex: 80%;
  background-color: white;
  padding: 4px;
}

.fakeimg {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}
.menu-button {
  background: rgba(38, 159, 175, 0.3);
  color: white;
  border: none;
  padding: 5px;
  font-size: 17px;
  cursor: pointer;
  display: none; /* Hide by default */
  
}

@media (max-width: 768px) {
  .menu-button {
    display: block; /* Show on small screens */
  }
  .side {
    display: none; /* Hide the sidebar */
  }
}

/* Sidebar Modal */
.sidebar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: red;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
