/* Container styling */
.estore-update-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.safety-message-textarea {
  border: 1px solid black;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  resize: vertical; /* Allow resizing only vertically */
}
/* Logo styling */
.estore-logo {
  max-width: 150px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Card component styling */
.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Form group */
.form-group {
  margin-bottom: 16px;
}

/* Form title */
h2, h3 {
  color: #333;
  margin-bottom: 16px;
  font-weight: 600;
}

/* Labels and Inputs */
label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
}
input {
  min-width: 10vw !important; /* Override any global setting */
  width: 50vw;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Update Button */
.update-button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-button:hover {
  background-color: #0056b3;
}
.hidden {
  display: none;
}

.visible {
  display: block;
}