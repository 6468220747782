/* Container for the table */
.table-container {
  margin-top: 20px;
}

/* Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Table Header */
.table th {
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
}

/* Table Rows */
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

/* Edit and Delete Buttons */
.table .btn {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

/* Edit Button */
.table .btn-warning {
  background-color: #f0ad4e;
  color: white;
  transition: background-color 0.3s ease;
}

.table .btn-warning:hover {
  background-color: #ec971f;
}

/* Delete Button */
.table .btn-danger {
  background-color: #d9534f;
  color: white;
  transition: background-color 0.3s ease;
}

.table .btn-danger:hover {
  background-color: #c9302c;
}

/* Button container to align buttons nicely */
.table .btn-container {
  display: flex;
  gap: 8px;
  justify-content: center;
}
