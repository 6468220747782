.checkbox-with-radio {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.radio-options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-left: 4px;
}

.radio-options label {
  display: flex;
  align-items: center;
  margin-right: 8px;
  gap: 7px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 8px;
   white-space: nowrap; /* Prevents line breaks */
}
