/* Addons.css */

.addons-container {
  max-width: 75%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.addons-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.addons-list {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping of list items */
  padding: 0;
  margin: 0;
}

.addons-list-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.addons-list-item input[type='checkbox'] {
  margin-right: 10px;
}


.addons-input {
  width: 35%;
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.addons-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  width: 105px;
}

.addons-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.addons-button:hover {
  background-color: #45a049;
}
/* index.css */
.removed-item {
  background-color: #f44336; /* Red background for removed items */
  color: white; /* White text color */
  padding: 5px 10px; /* Add padding for better spacing */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Adjust font size */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.removed-item:hover {
  background-color: #d32f2f; /* Darker red when hovered */
}

.removed-ingredients h5 {
  color: #f44336; /* Red title for removed ingredients */
}