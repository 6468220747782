/* Container for all the days */
.days-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  gap: 16px;
}
.button{
  border-radius: "40px";
}
/* Each individual day card */
.day-card {
  flex: 1 1 100px; /* Flex-grow, flex-shrink, and min-width for responsiveness */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Flex container for open and close time inputs */
.period-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
/* Responsive media query for smaller screens */
@media (min-width: 768px) {
  .day-card {
    flex: 1 1 25%; /* Each day will take full width on smaller screens */
  }
}
/* Responsive media query for smaller screens */
@media (max-width: 768px) {
  .day-card {
    flex: 1 1 50%; /* Each day will take full width on smaller screens */
  }
}
