/* Style for the action column */
.action-column {
  display: flex;
  gap: 10px;
  justify-content: center;
}

/* Button styles */
.button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-edit {
  background-color: #4caf50; /* Green */
  color: white;
}

.button-delete {
  background-color: #f44336; /* Red */
  color: white;
}

.button:hover {
  opacity: 0.8;
}
