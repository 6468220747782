/* src/components/index.css */
.file-upload-container {
  margin: 15px 0;
  width: 60vw;
}

.file-upload-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.file-upload-container input[type="file"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
