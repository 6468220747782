.checkout-container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.checkout-section {
  margin-bottom: 20px;
}

.checkout-cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.shipping-form,
.payment-form {
  display: flex;
  flex-direction: column;
}

.shipping-form input,
.payment-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.place-order-btn {
  background-color: #28a745;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.place-order-btn:hover {
  background-color: #218838;
}
/* Modal styles */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  margin: 0 auto;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-agree {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
