.modal-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.modal-controls {
  margin-top: 2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Enable wrapping */
  flex-direction: row; /* Ensure row direction */
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Add space between items */
}
.footer-buttons {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  justify-content: flex-end;
}

.number-picker-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.time-picker {
  display: flex;
  flex-direction: column;
}

.hour-picker, .minute-picker {
  display: flex;
  align-items: center;
}

button {
  padding: 5px 10px;
  font-size: 1.2rem;
  margin: 0 5px;
  cursor: pointer;
}

input {
  width: 50px;
  padding: 5px;
  font-size: 1.2rem;
  text-align: center;
  margin: 0 5px;
}

.colon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 25px 10px;
}
.step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 30px;
}



.anticipated-time {
  font-size: 1.2rem;
  text-align: right;
  width: 100%;
  padding-right: 20px;
  margin-top: 10px;
  font-weight: bold;
}