.status-indicator {
  display: flex; /* Align items in a row */
  flex-direction: row;
  /* align-items: center; */ /* Vertically center */
  gap: 5px; /* Add space between circle and text */
  margin-left: -16px;
  margin-top: 50px;
}

.status-circle {
  width: 10px; /* Size of the circle */
  height: 10px;
  border-radius: 50%; /* Make it circular */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.status-circle.open {
  background-color: green; /* Green for open */
}

.status-circle.closed {
  background-color: red; /* Red for closed */
}

.status-text {
  font-size: 9px;
  font-weight: bold;
  color: #333; /* Adjust text color */
}
