.price-and-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0.6rem 0.01rem 0.6rem; /* Adjust spacing as needed top, right, bottom, left*/
}

.price {
  font-size: 1rem;
  font-weight: 500;
}

.add-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #007bff; /* Adjust color as needed */
  transition: transform 0.2s;
}

.add-icon:disabled {
  color: gray;
  cursor: not-allowed;
}

.add-icon:hover:not(:disabled) {
  transform: scale(1.1); /* Adds a hover effect */
}
.item-label {
  display: flex; /* Arrange elements in a row */
  flex-wrap: wrap; /* Allow elements to wrap to the next line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  /* white-space: nowrap; */ /* Prevent breaking text onto a new line */
  white-space: normal;  /* Allow text to wrap */
  gap: 0.5rem; /* Add spacing between elements */
  margin: -0.9rem 0 -0.9rem 0.3rem;
}

.calorie-text {
  font-size: .9em;
  font-style: italic;
  font-weight: 300; /* Light font weight */
  white-space: nowrap; /* Prevent breaking text */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for long calorie text */
}
.product-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 8px; /* Add space between product cards */
  justify-content: center; /* Center products */
  margin: .1rem .9rem .1rem .9rem;

}

.product-card {
  flex: 1 0 200px;  /* Adjusts item size for responsiveness */
  position: relative; /* Allows positioning of child elements inside the card */
  /* flex: 0 1 250px; */ /* Allows wrapping with a minimum width of 300px */
  border: none;
  padding: 0 0 .2rem 0 ;
  margin: 0;
  margin-bottom: 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   background-color: #fff; /* White background */
   /* width: 50%; */  /* Ensure the card doesn't exceed the container width */
   max-width: 20rem;  /* Ensure the card doesn't exceed the container width */
  overflow: hidden;  /* Prevent content from overflowing */
   flex-direction: column;
   display: flex;
   height: 310px;

}
.product-card .item-name {
    flex-grow: 1; /* Allow the item name to take remaining space */
}
.price, .calorie-text {
  white-space: nowrap;  /* Prevent text from wrapping in price and calorie text */
  overflow: hidden;
  text-overflow: ellipsis;  /* Handle text overflow with ellipsis */
}
.product-card img {
  width: 100%; /* Ensure images fit within the product card */
  max-width: 100%; /* Ensure images fit within the product card */
  height: 250px; /* Fixed height for consistent card sizes */
  object-fit: cover; /* Ensures the image maintains aspect ratio without distortion */
  border-radius: 8px;
}

/* Container for the small image and estore name */
.product-card .estore-info {
  position: absolute;
  top: .3em; /* Position from the top */
  left: .3em; /* Position from the left */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically align items */
  background: rgba(0, 0, 0, 0.2); /* Translucent black background */
  padding: 5px 3px 0px 0px; /* Padding inside the row */
  border-radius: 8px; /* Rounded corners */
  color: white; /* Text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Small image inside the row */
.product-card .estore-info .small-image {
  width: 40px; /* Set width for the small image */
  height: 40px; /* Set height for the small image */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 50%; /* Circular shape */
  border: 2px solid white; /* Optional border for visibility */
  margin-right: 6px; /* Space between image and text */

}

/* E-store name */
.product-card .estore-info .estore-name {
  font-size: .7rem; /* Font size */
  font-weight:lighter; /* Bold text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.product-card h3 {
  font-size: 1.5rem;
  margin: 3px 0;
}

.product-card p {
  font-size: 1rem;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}

button {
  display: flex;
  flex-direction: row;
  border-radius: 75px;
  align-items: center;
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #218838;
}

.add-icon {
  margin-right: 8px;
  font-size: 18px;
}

.special-event-card {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 5px; /* Add space between product cards */
  justify-content: center; /* Center products */
}
.special-event-card img-event {
  max-width: 100%; /* Ensure images fit within the product card */
  height: 350px; /* Fixed height for consistent card sizes */
  object-fit: cover; /* Ensures the image maintains aspect ratio without distortion */
  border-radius: 8px;
}
.special-event-card .estore-desc {
  position: absolute;
  top: .3em; /* Position from the top */
  left: .3em; /* Position from the left */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically align items */
  background: rgba(0, 0, 0, 0.2); /* Translucent black background */
  padding: 5px 3px 0px 0px; /* Padding inside the row */
  border-radius: 8px; /* Rounded corners */
  color: white; /* Text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
/* Small image inside the row */
.special-event-card .estore-desc .small-image-event {
  width: 40px; /* Set width for the small image */
  height: 40px; /* Set height for the small image */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 50%; /* Circular shape */
  border: 2px solid white; /* Optional border for visibility */
  margin-right: 6px; /* Space between image and text */
}

/* E-store name */
.special-event-card .desc .estore-name {
  font-size: .7rem; /* Font size */
  font-weight:lighter; /* Bold text */
  white-space: nowrap; /* Prevent text from wrapping */
}
/* Event description bottom */
.special-event-card .event-description {
  position: relative;
  bottom: .3em; /* Position from the top */
  left: .3em; /* Position from the left */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically align items */
  background: rgba(0, 0, 0, 0.2); /* Translucent black background */
  padding: 5px 3px 0px 0px; /* Padding inside the row */
  border-radius: 8px; /* Rounded corners */
  color: white; /* Text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.special-event-card .event-description:hover::after {
  content: attr(data-full-text); /* Use the full text from a custom attribute */
  position: absolute;
  bottom: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: normal;
  max-width: 300px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 368px) {
  .product-card {
    flex: 1 0 100%; /* Make each card take up full width on smaller screens */
     display: flex;
    flex-direction: column;
  }
  .item-label {
    font-size: 0.9rem;  /* Adjust font size for readability */
    white-space: normal; /* Allow text to wrap on smaller screens */
  }
}