/* SpiceLevels.css */

.spice-levels-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.spice-levels-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.spice-levels-list {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping of list items */
  padding: 0;
  margin: 0;
}

.spice-levels-list-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.spice-levels-list-item input[type='checkbox'] {
  margin-right: 10px;
}

.spice-levels-input {
  width: calc(100% - 80px);
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.spice-levels-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.spice-levels-button:hover {
  background-color: #45a049;
}
