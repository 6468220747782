.container {
  width: 350px;
  height: 350px;
  border: 2px solid black;
  border-radius: 5px;
}

.container .img-container {
  width: 100%;
  height: 200px;
}

.container .img-container img {
  width: 100%;
  height: 100%;
}

.container .card-info {
  width: 100%;
  height: auto;
}