.custom-navbar {
  background-color: white;
  width: 18vw; /* Fixed width for the navbar */
  height: 100vh;
  padding: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  overflow: hidden;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.nav-item {
  /* width: 100%; */ /* Ensure the nav items take up the full width */
  margin: 1px 0;
}

.nav-link {
  color: #333;
  text-decoration: none;
  display: flex; /* Use flex to align icons and text */
  /*  align-items: center;  */ /* Align icon and text vertically */
  padding: 8px 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* justify-content: flex-start; */
  margin-left: 5px;
}

.nav-link:hover {
  background-color: white;
  color: #0096ff;
  border-radius: 8px;
}

.nav-link svg {
  margin-right: 1px; 
}

.nav-link span {
  display: inline-block; /* Ensure text is inline with icon */
  margin-left: 2px; /* Add some space between icon and text */
}


.estore-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: fill;
  margin-bottom: 10px; /* Adjusted margin */
  margin-left: 5%; /* Keep logo centered */
}

.estore-name {
  font-weight: bold;
  color: #f9f9f9;
  font-size: 1.2rem;
  margin-bottom: 10px; /* Adjusted margin */
  text-transform: capitalize;
  margin-top: 1px;
  margin-left: 5%; /* Adjusted margin */
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
  overflow-wrap: break-word;
}

.open-hours {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  font-size: 0.9rem;
  margin-left: 10px;
}
.text-link {
  display: inline-block; /* Ensures visibility */
  font-size: 1rem; /* Adjust size */
}
/* Media query for small screens */
@media (max-width: 768px) {
  .custom-navbar {
  background-color: white;
  width: 18vw; /* Fixed width for the navbar */
  height: 100vh;
  padding: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  overflow: hidden;
}
  .custom-navbar {
    width: 70px; /* Adjust navbar width on smaller screens */
  }
  .open-hours {
    display: none;
  }
  .nav-link span {
  display: none; /* Ensure text is inline with icon */
}
  /* Hide text on small screens */
  .text-link {
    display: none;
  }

  /* Ensure icon links are visible */
  .icon-link {
    display: block; /* Show icons */
    margin-left: 2px;
    font-size:x-large;
  }

  .nav-link {
    padding: 3px 5px; /* Adjust padding for smaller screens */
  }

  /* Ensure icons and text are aligned in a row for small screens */
  .navbar-nav {
    padding-left: 5px; /* Ensure icons and text are aligned to the left */
  }
}
